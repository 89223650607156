const PROJECT = "Internet_end_credit";
const FOLDERS: Record<string, string> = {
	POLAROIDS: "pola",
	PAINTINGS: "paintings",
	DURER: "durer",
	ICONS: "icons",
};

export const IMAGES = [
	"deramp_01",
	"deramp_02",
	"deramp_03",
	"deramp_04",
	"deramp_05",
	"deramp_06",
	"deramp_07",
	"deramp_08",
	"deramp_09",
	"deramp_10",
	"deramp_11",
	"deramp_12",
	"deramp_13",
];

const paintingsIds = [
	{ id: "plage_aurian_guerard_xbtibc", name: "plage" },
	{ id: "shadow_aurian_guerard_kniik7", name: "shadow" },
	{ id: "Dusseldoref_aurian_guerard_eqtmk9", name: "shadow" },
	{ id: "forgetthings_aurian_guerard_uhaauk", name: "things" },
	{ id: "baigeuse_aurian_guerard_gutphz", name: "baigneuses" },
	{ id: "17112016-IMG_0920_ufdolp", name: "study" },
	{ id: "17112016-IMG_0917_o2pgx7", name: "study" },
	{ id: "baptisme_aurian_guerard_kfshcy", name: "baptism" },
	{ id: "whitesnow_aurian_guerard_z5wmk9", name: "white snow" },
	{ id: "sleepingbeauty_aurian_guerard_vqzvwh", name: "sleeping beauty" },
	{ id: "Western_Digital_aurian_guerard_des_lauriers_nhoxnz", name: "WD" },
	{ id: "Ordinateur_aurian_guerard_des_lauriers_kklcgp", name: "Ordinateur" },
	{ id: "Medium_aurian_guerard_des_lauriers_amhzr8", name: "medium" },
	{ id: "ligature_aurian_guerard_des_lauriers_vdgujj", name: "ligature" },
	{ id: "batterie_aurian_guerard_des_lauriers_y1a9mu", name: "battery" },
	{ id: "Antishockmemory_aurian_guerard_zgloe7", name: "anti-shock" },
	{ id: "écouteur_aurian_guerard_des_lauriers_slm7ty", name: "earphones" },
];

const polaIds = [
	{ id: "Angel_Come_Aurian_Guerard_16_nic1ro", name: "1" },
	{ id: "Angel_Come_Aurian_Guerard_15_xxckan", name: "2" },
	{ id: "Angel_Come_Aurian_Guerard_14_hc2wlv", name: "3" },
	{ id: "Angel_Come_Aurian_Guerard_13_mucmkb", name: "4" },
	{ id: "Angel_Come_Aurian_Guerard_12_nvms5q", name: "5" },
	{ id: "Angel_Come_Aurian_Guerard_11_igrjwc", name: "6" },
	{ id: "Angel_Come_Aurian_Guerard_10_dt0hs9", name: "7" },
	{ id: "Angel_Come_Aurian_Guerard_9_qnzznr", name: "8" },
	{ id: "Angel_Come_Aurian_Guerard_8_hfzntq", name: "9" },
	{ id: "Angel_Come_Aurian_Guerard_4_jgytmp", name: "10" },
	{ id: "Angel_Come_Aurian_Guerard_7_wunptt", name: "11" },
	{ id: "Angel_Come_Aurian_Guerard_5_ed7i4a", name: "12" },
	{ id: "Angel_Come_Aurian_Guerard_6_ips6b3", name: "13" },
	{ id: "Angel_Come_Aurian_Guerard_2_qz77md", name: "14" },
	{ id: "Angel_Come_Aurian_Guerard_3_czitlx", name: "15" },
	{ id: "Angel_Come_Aurian_Guerard_1_lfcdze", name: "16" },
];

export { paintingsIds, polaIds, PROJECT, FOLDERS };
