import type { PolyTextType } from "../types";

export const exlibris: PolyTextType = [
	[`While the inflation of images is on an endless rise`],
	[`And as I grew older`],
	[`The doubt that once inhabited me`],
	[`Born at the tip of my pencil`],
	[`Kept growing`],
	[`And the desire of being an artist`],
	[`Could't keep up with the guilt of adding an image to the world`],
	[`Slowly I started painting only for myself`],
	[
		`The diagrams carrefully crafted into my pictures were no longer making as much sense if they were show to a random audience.`,
	],
	[`Or to an audience at all`],
	[`_`],
	[`In the meantime`],
	[
		`The overwhelming feeling of the disparition of images made itself more and more present`,
	],
	[`The condition of existence of images is bearly still existing`],
	[`As the inexorable uncovering of meaning advance`],
	[`The obviousness territories' get bigger and bigger`],
	[`The experience has moved away in an unsolvable distance`],
	[`And nothing is to remain hidden`],
	[`_`],
	[`exept for images`, `expect for images`],
	[`_`],
	[`truely painting is the only thing that make sense`],
];

export const latency: PolyTextType = [
	[`Everything now lives in an unresolved latency`, `take my heart away`],
];

export const help: PolyTextType = [
	[`help : display help`],
	[`reset : clear the console`],
	// [`tree : display folder tree`],
	[`anthume : enter anthume mode`],
	[`posthume : enter posthume mode`],
	[`exlibris : display exlibris`],
];

export const NOTEPAD: Record<string, string> = {
	POLAROIDS: `
  crux volvitur orbis
  `,
	PAINTINGS: `
  These are images,
  those don't live in the realm of language,
  use your eyes.
`,
};
