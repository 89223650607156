export const KANJI = [
	"゠",
	"A",
	"𝛂",
	"a",
	"ァ",
	"B",
	"𝛽",
	"b",
	"ア",
	"C",
	"𝛾",
	"c",
	"ィ",
	"D",
	"𝛤",
	"d",
	"イ",
	"E",
	"𝛿",
	"𝛥",
	"e",
	"ゥ",
	"F",
	"𝛆",
	"ἔ",
	"f",
	"ウ",
	"G",
	"𝟋",
	"g",
	"ェ",
	"H",
	"Ͷ",
	"h",
	"エ",
	"I",
	"ϛ",
	"i",
	"ォ",
	"J",
	"ζ",
	"j",
	"オ",
	"K",
	"ͱ",
	"k",
	"カ",
	"L",
	"η",
	"ἤ",
	"l",
	"ガ",
	"M",
	"ϑ",
	"θ",
	"m",
	"キ",
	"N",
	"𝛊",
	"Ἶ",
	"n",
	"ギ",
	"O",
	"ϰ",
	"ϗ",
	"o",
	"ク",
	"P",
	"𝛌",
	"𝚲",
	"p",
	"グ",
	"Q",
	"μ",
	"q",
	"ケ",
	"R",
	"ξ",
	"𝚵",
	"r",
	"ゲ",
	"S",
	"ϖ",
	"ℼ",
	"s",
	"コ",
	"T",
	"ϻ",
	"t",
	"ゴ",
	"U",
	"Ϟ",
	"u",
	"サ",
	"V",
	"𝛠",
	"v",
	"ザ",
	"W",
	"𝚺",
	"w",
	"シ",
	"X",
	"Ͽ",
	"x",
	"ジ",
	"Y",
	"φ",
	"y",
	"ス",
	"Z",
	"ψ",
	"Ϡ",
	"z",
	// 'ズ',
	// 'セ',
	// 'ゼ',
	// 'ソ',
	// 'ゾ',
	// 'タ',
	// 'ダ',
	// 'チ',
	// 'ヂ',
	// 'ッ',
	// 'ツ',
	// 'ヅ',
	// 'テ',
	// 'デ',
	// 'ト',
	// 'ド',
	// 'ナ',
	// 'ニ',
	// 'ヌ',
	// 'ネ',
	// 'ノ',
	// 'ハ',
	// 'バ',
	// 'パ',
	// 'ヒ',
	// 'ビ',
	// 'ピ',
	// 'フ',
	// 'ブ',
	// 'プ',
	// 'ヘ',
	// 'ベ',
	// 'ペ',
	// 'ホ',
	// 'ボ',
	// 'ポ',
	// 'マ',
	// 'ミ',
	// 'ム',
	// 'メ',
	// 'モ',
	// 'ャ',
	// 'ヤ',
	// 'ュ',
	// 'ユ',
	// 'ョ',
	// 'ヨ',
	// 'ラ',
	// 'リ',
	// 'ル',
	// 'レ',
	// 'ロ',
	// 'ヮ',
	// 'ワ',
	// 'ヰ',
	// 'ヱ',
	// 'ヲ',
	// 'ン',
	// 'ヴ',
	// 'ヵ',
	// 'ヶ',
	// 'ヷ',
	// 'ヸ',
	// 'ヹ',
	// 'ヺ',
	// '・',
	// 'ー',
	// 'ヽ',
	// 'ヾ',
	// 'ヿ',
];
