import type { PolyTextType } from "../types";

export const whereIsHome: PolyTextType[] = [
	[
		["kaval sviri / les mystere des voix bulgare"],
		["A kaval is playing, mother"],
		["up", "down", "mother", "up", "down", "mother."],
		["A kaval is playing mother,"],
		["__"],
		["up", "down", "mother", "near the village."],
		["I will go, mother, to see it"],
		["to see it, mother, to hear it."],
		["__"],
		["If it's someone from our village"],
		["I'll love him only for a short time,"],
		["If it's a foreigner"],
		["I'll love him all my life."],
	],
	[
		["this must be the place / talking head"],
		["Home is where I want to be"],
		["Pick me up and turn me around"],
		["I feel numb, born with a weak heart"],
		["I guess I must be having fun"],
	],
	[
		["enjoy the silence / depeche mode"],
		["All I ever wanted"],
		["All I ever needed is here in my arms"],
		["Words are very unnecessary"],
		["They can only do harm"],
	],
	[["where is home / burial"], ["Where is home?"], ["Where is it?"]],
	[
		["a forest / the cure"],
		["I know it's too late"],
		["I'm lost in a forest"],
		["All alone"],
		["The girl was never there"],
		["It's always the same"],
	],
	[
		["christian Brother / Elliot smith"],
		["Come here by me, I want you here"],
		["Nightmares become me, it's so fucking clear"],
	],
	[
		["live with me / massive attack"],
		["Plans and schemes"],
		["Hopes and fears"],
		["Dreams that deny for all these years"],
	],
	[["personal jesus / depeche mode"], ["Flesh and bone by the telephone"]],
	[
		["o superman / laurie anderson"],
		["Cause when love is gone, there's always justice"],
		["And when justice is gone, there's always force"],
		["And when force is gone, there's always Mom. Hi Mom!"],
	],
	[
		["jesus of suburbia / green days"],
		["It says, 'Home is where your heart is,' but what a shame"],
		["'Cause everyone's heart doesn't beat the same"],
		["It's beating out of time"],
	],
	[
		["home / NIN"],
		["To break from what we're tied to"],
		["God knows how much I've tried to"],
		["I used to know who I was"],
		["Until you came along"],
		["I return"],
		["To the only place I've ever felt"],
		["That I belong"],
	],
	[
		["ghost / depeche mode"],
		["Now you're running in circles"],
		["Chasing imaginary footsteps"],
	],
	[
		["a prayer for england / massive attack"],
		["Let not another child be slain"],
		["Let not another search be made in vain"],
	],
	[["not over yet / grace"], ["Remember me"]],
	[
		["not in love / crystal castles"],
		["And we were lovers"],
		["Now we can't be friends"],
		["'Cause it's cold outside"],
		["When you coming home?"],
		["'Cause it's hot inside"],
		["Isn't that enough?"],
	],
	[
		["nothing's impossible / depeche mode"],
		["Just give me a reason, some kind of sign"],
		["I'll need a miracle to help me this time"],
	],
	[
		["running up that hill / placebo"],
		["And if I only could"],
		["I'd make a deal with God"],
		["And I'd get him to swap our places"],
	],
	[
		["broken home / burial"],
		["Whose fault"],
		["No one but myself"],
		["And you know..."],
	],
	[["love will tear us apart / joy division"], ["Why is the bedroom so cold?"]],
	[
		["Utai IV / ghost in the shell"],
		["Proposing marriage, the god shall descend"],
		["The night clears away and the chimera bird will sing"],
	],
	[
		["right where it belongs / NIN"],
		["See the safety of the life you have built"],
		["Everything where it belongs"],
	],
	[
		["another day / this mortal coil"],
		["She's at the door, she wants to score, she really needs to say"],
		["'[...] And that I never had one of your children'"],
		["And without a sound, I turn around and I walk away"],
	],
	[
		["true love will find you in the end / daniel johnston"],
		["Don't be sad, I know you will "],
		["But don't give up until"],
		["True love will find you in the end  "],
		["This is a promise with a catch "],
		["Only if you're looking can it find you  "],
		["Cause true love is searching too"],
		["But how can it recognize you  "],
		["Unless you step out into the light, the light"],
	],
];

export const whereIsHomeFR: PolyTextType[] = [
	[
		["kaval sviri / les mystères des voix bulgares"],
		["Un kaval joue, maman,"],
		["haut, bas, mère, haut, bas, mère."],
		["Un kaval joue à la mère,"],
		["__"],
		["en haut, en bas, mère, près du village."],
		["Je vais, mère, le voir"],
		["le voir, mère, l'entendre."],
		["__"],
		["Si c'est quelqu'un de notre village"],
		["Je l'aimerai seulement pour un court instant,"],
		["Si c'est un étranger"],
		["Je l'aimerai toute ma vie."],
	],
	[
		["ce doit être l'endroit /talking heads"],
		["La maison est l'endroit où je veux être"],
		["Prends-moi et retourne-moi"],
		["Je me sens engourdi, né avec un cœur faible"],
		["Je suppose que je dois m'amuser"],
	],
	[
		["profitez du silence / dépeche mode"],
		["Tout ce que j'ai jamais voulu"],
		["Tout ce dont j'ai toujours eu besoin est ici dans mes bras"],
		["Les mots sont inutiles"],
		["Ils ne peuvent que blesser"],
	],
	[["où est la maison / burial"], ["Où est la maison?"], ["Où est-il?"]],
	[
		["une forêt / the cure"],
		["Je sais que c'est trop tard"],
		["je suis perdu dans une forêt"],
		["Tout seul"],
		["La fille n'a jamais été là"],
		["C'est toujours la meme chose"],
	],
	[
		["frère chrétien / Elliot smith"],
		["Viens ici près de moi, je te veux ici"],
		["Les cauchemars deviennent moi, c'est tellement clair"],
	],
	[
		["vivre avec moi / massive attack"],
		["Plans et projets"],
		["Espoirs et peurs"],
		["Des rêves refoulés depuis toutes ces années"],
	],
	[["personal jesus/ Depeche Mode"], ["En chair et en os au téléphone"]],
	[
		["ô superman / laurie anderson"],
		["Parce que quand l'amour s'en va, il y a toujours la justice"],
		["Et quand la justice n'est plus là, il y a toujours la force"],
		["Et quand la force n'est plus là, il y a toujours maman. Salut maman!"],
	],
	[
		["suburbam jesus / Green Days"],
		["Il dit : 'La maison est là où est ton cœur', mais quel dommage"],
		["Parce que le cœur de tout le monde ne bat pas de la même façon"],
		["mais à contre temps"],
	],
	[
		["home / NIN"],
		["Pour rompre avec ce à quoi nous sommes liés"],
		["Dieu sait combien j'ai essayé"],
		["Je savais qui j'étais"],
		["Jusqu'à ce que tu viennes"],
		["je retourne"],
		["Au seul endroit où je me suis jamais senti"],
		["auquel j'appartiens"],
	],
	[
		["ghost / depeche mode"],
		["Maintenant tu tournes en rond"],
		["À la poursuite de traces imaginaires"],
	],
	[
		["une prière pour l'Angleterre / massive attack"],
		["Ne laissez pas un autre enfant être tué"],
		["Ne laissez pas une autre recherche être faite en vain"],
	],
	[["not over yet / grace"], ["Souviens-toi de moi"]],
	[
		["not in love / crystal castles"],
		["Et nous étions amants"],
		["Maintenant nous ne pouvons pas être amis"],
		["Parce qu'il fait froid dehors"],
		["Quand tu rentres à la maison ?"],
		["Parce qu'il fait chaud à l'intérieur"],
		["N'est-ce pas suffisant ?"],
	],
	[
		["rien n'est impossible / depeche mode"],
		["Donne-moi juste une raison, une sorte de signe"],
		["J'aurai besoin d'un miracle pour m'aider cette fois"],
	],
	[
		["running up that hill / placebo"],
		["Et si seulement je pouvais"],
		["Je ferais un marché avec Dieu"],
		["Et je lui demanderais d'échanger nos places"],
	],
	[
		["broken home / burial"],
		["c'est ma faute"],
		["celle de personne d'autre"],
		["Et vous savez..."],
	],
	[
		["l'amour nous déchirera / division de la joie"],
		["Pourquoi la chambre est-elle si froide ?"],
	],
	[
		["Utai IV / fantôme dans la coquille"],
		["En proposant le mariage, le dieu descendra"],
		["La nuit s'éclaircit et l'oiseau chimère chantera"],
	],
	[
		["right where it belongs / NIN"],
		["Voyez la sécurité de la vie que vous avez construite"],
		["Tout à sa place"],
	],
	[
		["another day / this mortal coil"],
		["Elle est à la porte, elle veut marquer, elle a vraiment besoin de dire"],
		["'[...] Et que je n'ai jamais eu un de tes enfants'"],
	],
	[
		["true love will find you in the end / daniel johnston"],
		["Ne sois pas triste, je sais que tu le feras"],
		["Mais n'abandonne pas avant"],
		["Le véritable amour te trouvera à la fin"],
		["C'est une promesse avec un piège"],
		["Ce n'est que si tu cherches qu'il pourra te trouver"],
		["Parce que le véritable amour cherche aussi"],
		["Mais comment peut-il te reconnaître"],
		["A moins que tu ne sors dans la lumière, la lumière"],
	],
];
